import React from "react";
import "./Spices.css";

const Textile = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "20vh",
          backgroundColor: "black",
          color: "white",
          textAlign: "center",
          verticalAlign: "center",
          paddingTop: "45px",
        }}
      >
        <h1>TEXTILE</h1>
      </div>
      <div
        className="about-block"
        style={{ width: "100%", minHeight: "40vh", backgroundColor: "white" }}
      >
        <div
          className="aboutBlock-left"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          <img
            src={
              "/Productsphoto/Handicrafts/TextileBased/SAREE/Chanderi Fabrics from Chanderi, MP.png"
            }
            style={{ width: "90%", height: "90%" }}
          />
        </div>
        <div
          className="aboutBlock-right"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            paddingTop: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          <h2>About Textile</h2>

          <p>
            The various kinds of traditional art displayed in the Indian Textile
            Handicrafts are phulkari art, jamawar, batik, block printing, resist
            printing, and tie and dye. The various products of Textile
            Handicrafts industry in India are cashmere shawl, pashmina shawl,
            banarasi silk saris, paithani saris, and gara saris.
          </p>
        </div>
      </div>
      <button className="Spices-head">
        <h2>Saree</h2>
      </button>
      <div className="cards">
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/SAREE/258867747_4703266809716823_5205961897981057370_n.jpg"
            }
            alt=""
          />
        </div>

        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/SAREE/304953065_5593353307374831_8954133968602231155_n.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/SAREE/Ajrak from Bhuj & Barmer.png"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/SAREE/Batik from Ujjain, Madhya Pradesh.png"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/SAREE/Ikat from Pochampally.png"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/SAREE/Lucknowi Chikan from Lucknow, UP.png"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/SAREE/Patan Patola from Gujarat.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/SAREE/Mysore Silk from Mysore, Karnataka.jpg"
            }
            alt=""
          />
        </div>
      </div>
      <button className="Spices-head">
        <h2>Wollen Clothes</h2>
      </button>
      <div className="cards">
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/236644080_4370556019654572_5769376555475945017_n.jpg"
            }
            alt=""
          />
        </div>

        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/274696345_5026861327357368_1436750683839012773_n.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/274762587_5028737477169753_5214558143503846919_n.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/Handmade Twisted Jute Rope Planters.webp"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/pexels-teona-swift-6850579.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/302049268_5585615641481931_8668202688419535896_n.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/pexels-anete-lusina-4792062.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              border: "2px solid #ebb4b4",
              padding: "20px",
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/TextileBased/SHAWLS.jpg"
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Textile;
