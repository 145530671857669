import React from "react";
import "./Spices.css";
import Metal from "./Metal";
import Textile from './Textile'
import Wooden from './Wooden'
import Bamboo from './Bamboo'

const Handicraft = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "20vh",
          backgroundColor: "black",
          color: "white",
          textAlign: "center",
          verticalAlign: "center",
          paddingTop: "45px",
        }}
      >
        <h1>Handicrafts</h1>
      </div>
      <div
        className="about-block"
        style={{ width: "100%", minHeight: "40vh", backgroundColor: "white" }}
      >
        <div
          className="aboutBlock-left"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          <img
            src={"/HeadImg/HANDICRAFTS CATEGORY PHOTO.jpg"}
            style={{ width: "90%", height: "90%" }}
          />
        </div>
        <div
          className="aboutBlock-right"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            paddingTop: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          <h2>About Handicrafts</h2>

          <p>
            Handicraft is a type of craft where people make things using only
            their hands or basic tools. The items are usually decorative and
            have a particular use. The amount of skill, patience and precision
            that goes into the making of these beautiful pieces is commendable.
            the handicrafts of India have been loved and respected worldwide and
            have left everyone awestruck
          </p>
        </div>
      </div>
   
      <button className="Spices-head">
        <h2>Stone Handicrafts</h2>
      </button>
      <div style={{textAlign:"center",textDecoration:"none",color:"grey",marginTop:"10px"}}><h2>- Table Top -</h2></div>
      <div className="cards">
   
        <div className="card">
        <div className="card-in">
          <img
            className="card__image"
            style={{
             
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/TABLE TOP/Walnut Table With White Epoxy Resin.jpeg"}
            alt=""
          />
          Walnut Table
          </div>
        </div>
        <div className="card">
        <div className="card-in">
          <img
            className="card__image"
            style={{
             
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/TABLE TOP/OCTA BLACK MARBLE INLAY TABLE TOP.jpeg"
            }
            alt=""
          />
          OCTA BLACK MARBLE<br/> INLAY TABLE TOP
          </div>
        </div>
        <div className="card">
        <div className="card-in">
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/TABLE TOP/OCTA MARBLE INLAY TABLE TOP.jpeg"}
            alt=""
          />
          OCTA MARBLE<br/> INLAY TABLE TOP
          </div>
        </div>
        <div className="card">
        <div className="card-in">
          <img
            className="card__image"
            style={{
            
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/TABLE TOP/Table Top Archives - Marble Inlay Handicraft Products.png"
            }
            alt=""
          />
          Marble Inlay <br/>Handicraft Products
          </div>
        </div>
        
      </div>
      {/* //// */}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Storage Boxes -</h2></div>
      <div className="cards">
      
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
             
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/STORAGE BOXES/MARBLE INLAY BOX.jpeg"}
            alt=""
          />
          MARBLE INLAY BOX
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/STORAGE BOXES/Marble Inlay Jewelry Box.png"
            }
            alt=""
          />Marble Inlay Jewelry Box
          </div>
          
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/STORAGE BOXES/Marble Lidded Box.jpeg"}
            alt=""
          />Marble Lidded Box
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/STORAGE BOXES/Marble Marquetry Inlay Jewelry Storage Box.jpeg"
            }
            alt=""
          />Marble Marquetry Inlay Jewelry Storage Box
          </div>
        </div>
        
      </div>
      {/* /////// 33333 */}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Kitchen Accesories -</h2></div>
      <div className="cards">
        
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/KITCHEN ACCESORIES/Marble Fruit Bowl.jpeg"}
            alt=""
          />Marble Fruit Bowl
          </div>
        </div>
        <div className="card">
        <div className="card-in">
          
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/KITCHEN ACCESORIES/Marble Inlay Round Plate.png"
            }
            alt=""
          />Marble Inlay Round Plate
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/KITCHEN ACCESORIES/Marble Pantryware.jpeg"}
            alt=""
          />Marble Pantryware
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/KITCHEN ACCESORIES/Marble serving boards and canisters.jpeg"
            }
            alt=""
          />Marble serving boards and canisters
          </div>
        </div>
        
      </div>
      {/* //// 4444*/}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Decoratives -</h2></div>
      <div className="cards">
      
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/DECORATIVES/Ashtray.jpeg"}
            alt=""
          />Ashtray
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/DECORATIVES/CANDLE HOLDER.jpg"
            }
            alt=""
          />CANDLE HOLDER
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/DECORATIVES/FLOWER POT.png"}
            alt=""
          />Flower Pot
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/DECORATIVES/LAMP.jpg"
            }
            alt=""
          />Lamp
          </div>
        </div>
        
      </div>
      {/* //////5555 */}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Coaster -</h2></div>
      <div className="cards">
       
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/COASTER/INLAY COASTER.jpg"}
            alt=""
          />Inlay Coaster
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/COASTER/MARBLE WITH WOODEN COASTER.jpeg"
            }
            alt=""
          />Marble With Wodden Coaster
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/COASTER/ROUNDED COASTER.jpeg"}
            alt=""
          />Rounded Coaster
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/COASTER/STAR SHAPE Marble Coasters.jpeg"
            }
            alt=""
          />Star Marble Coaster
          </div>
        </div>
        
      </div>
      {/* ///////6666 */}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Chess -</h2></div>
      <div className="cards">
        
        <div className="card">
        <div className="card-in">
          
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/CHESS/Chess Table Green Marble.jpeg"}
            alt=""
          />Chess Table Green Marble
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/CHESS/Marble INLAY Chess Board.jpeg"
            }
            alt=""
          />Marble Inlay Chess Board
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/CHESS/OCTAGONAL CHESS.jpeg"}
            alt=""
          />Octagonal Chess 
          </div>
        </div>
        <div className="card">
        <div className="card-in">
          
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/CHESS/OCTAGONAL Marble Inlay Chess Board.jpeg"
            }
            alt=""
          />Octagonal Marble Inlay Chess Board
          </div>
        </div>
        
      </div>
      {/* ////77777 */}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Bathroom Accesories -</h2></div>
      <div className="cards">
       
        <div className="card">
        <div className="card-in">
          
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/BATHROOM ACCESORIES/Bath Accessories.jpeg"}
            alt=""
          />Bath Accesories
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/BATHROOM ACCESORIES/SOAP DISH.jpeg"
            }
            alt=""
          />Soap Dish</div>
        </div>
        <div className="card">
        <div className="card-in">
          
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/stoneCraft/BATHROOM ACCESORIES/SOAP DISPENSER.jpeg"}
            alt=""
          />Soap Dispenser</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/stoneCraft/BATHROOM ACCESORIES/TOOTHBRUSH HOLDER.jpeg"
            }
            alt=""
          />ToothBrush  Holder
          </div>
        </div>
        
      </div>
      {/* <button className="Spices-head">
        <h2>Imitation Jwellary Handicrafts</h2>
      </button>
      <div className="cards">
        <div className="card">
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/imitation jwellery handicrafts/pexels-harry-cooke-6805687.jpg"
            }
            alt=""
          />
        </div>

        <div className="card">
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Handicrafts/imitation jwellery handicrafts/307035176_5610236852353143_2256691317643243184_n.jpg"}
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/imitation jwellery handicrafts/FB_IMG_1667808136336.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/imitation jwellery handicrafts/FB_IMG_1667808140963.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Handicrafts/imitation jwellery handicrafts/FB_IMG_1667808119231.jpg"}
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/imitation jwellery handicrafts/courtney-rose-35yy-0cwIMw-unsplash.jpg"
            }
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Handicrafts/imitation jwellery handicrafts/301271397_5580068775369951_7701048780298385140_n.jpg"}
            alt=""
          />
        </div>
        <div className="card">
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Handicrafts/imitation jwellery handicrafts/260249069_4690473247662846_9056520566169778297_n.png"
            }
            alt=""
          />
        </div>
        
        
      </div> */}
      {/* <Metal/>
      <Textile/> */}
      <Wooden/>
      <Bamboo/>

    </div>
  );
};

export default Handicraft;
