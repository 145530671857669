import React from 'react'
import "./Spices.css"


const Clothing = () => {
  return (
    <div>
        <div style={{width:"100%",height:"20vh",backgroundColor:"black",color:"white",textAlign:"center",verticalAlign:"center",paddingTop: '45px'}}>
          <h1>Clothing</h1>
        </div>
        {/* <div className="about-block" style={{width:"100%",minHeight:"40vh",backgroundColor:"white"}}>
          <div className='aboutBlock-left' style={{width:"100%",height:"100%",backgroundColor:"white",textAlign:"center",paddingTop:"20px"}}>
            <img src={"/Productsphoto/Handicrafts/WoodenCraft/Decorative Wooden Flower Vase.png"} style={{width:"90%",height:"90%"}}/>
          </div>
          <div className='aboutBlock-right' style={{width:"100%",height:"100%",backgroundColor:"white",paddingTop:"20px",paddingRight:"20px",paddingLeft:"20px"}}>
                <h2>Clothing</h2>
                
                  <p>Wooden handicrafts are made by decorating or carving the wood to create beautiful items. Every craftsman creates a unique handicraft according to their individual talent. India is considered as the land of tradition and culture. Indian craftsmen create distinctive masterpieces with different shapes, patterns, colours and sizes. These handicrafts are famous all over the earth for their ability to last. Wooden handicrafts of India are known for its utility and beauty. These handicrafts embrace furniture, boxes, beads, finely engraved figures, accessories etc. India is a tropical country and it has deciduous or evergreen forests. Besides the wooden furniture items, beautiful toys, photo frames, dolls were also made. The art of decorating and carving the wood is considered as one of the most notable forms. It requires a lot of hard work and dedication to carve a piece of wood and make something unique and beautiful out of it.
</p>
              
          </div>

</div> */}
<button className="Spices-head">
        <h2>Clothing</h2>
      </button>
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Cotton Items -</h2></div>
      
      <div className="cards">
       
      <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/COTTON ITEMS/cototn-triusers.jpg"} alt=""></img>
    Cotton Trousers
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/COTTON ITEMS/Cotton-Shirt.png"} alt=""></img>
    Cotton Shirt
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/COTTON ITEMS/Cotton-T-shirt.png"} alt=""></img>
    Cotton T Shirt
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/COTTON ITEMS/kurti.jpg"} alt=""></img>
    Kurti
    </div>
  </div>
        
      </div>

      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Bedsheets -</h2></div>
      
      <div className="cards">
       
      <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/BEDSHEETS/Bed-sheet.jpg"} alt=""></img>
    Bed Sheet
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/BEDSHEETS/cotton-blend-bed-sheets.png"} alt=""></img>
    Cotton Blend Bed Sheet
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/BEDSHEETS/egyptian-cotton-bed-sheets.png"} alt=""></img>
    Egyptian Cotton Bed Sheet
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/BEDSHEETS/Linen-Bed-Sheets.png"} alt=""></img>
    Linen Bed Sheet
    </div>
  </div>
        
      </div>
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Jeans -</h2></div>
      
      <div className="cards">
       
      <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/JEANS/BOOT CUT JEANS.png"} alt=""></img>
    Boot Cut Jeans
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/JEANS/high-rise.png"} alt=""></img>
    High Rise Jeans
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/JEANS/SKINNY-JEANS.png"} alt=""></img>
    Skinny Jeans
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/JEANS/SLIM-FIT.png"} alt=""></img>
    Slim Fit
    </div>
  </div>
        
      </div>
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Pillow Covers -</h2></div>
      
      <div className="cards">
       
      <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/PILLOW COVERS/HANDCRAFTED COVER.jpg"} alt=""></img>
    Handicrafted Cover
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/PILLOW COVERS/MATTE COVERS.jpg"} alt=""></img>
    Matte Covers
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/PILLOW COVERS/PRINTED COVER.jpg"} alt=""></img>
    cdcdcdcd
    </div>
  </div>
  {/* <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/6. IMITATION JEWELLERY/CHOKER.jpeg"} alt=""></img>
    cdcdcdcd
    </div>
  </div> */}
        
      </div>
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Saree -</h2></div>
      
      <div className="cards">
       
      <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/SAREE/Ajrak from Bhuj & Barmer.png"} alt=""></img>
    Ajrak From Bhuj & Barmer
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/SAREE/Bagh Prints from Bagh, Madhya Pradesh.png"} alt=""></img>
    Prints Fro Bagh
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/SAREE/Banarasi Silk from Banaras.png"} alt=""></img>
    Banarasi Silk
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/SAREE/Chanderi Fabrics from Chanderi, MP.png"} alt=""></img>
    Chanderi Fabrics From Chanderi
    </div>
  </div>
        
      </div>
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Towels -</h2></div>
      
      <div className="cards">
       
      <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/TOWELS/bath-towels.png"} alt=""></img>
    Bath Towels
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/TOWELS/Finger-Towel.png"} alt=""></img>
    Finger Towel
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/TOWELS/Hand-Towel.png"} alt=""></img>
    Hand Towel
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/TOWELS/Washcloth.png"} alt=""></img>
    Wash Cloth
    </div>
  </div>
        
      </div>
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>- Wollen Clothes -</h2></div>
      
      <div className="cards">
       
      <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/WOOLEN CLOTHES/KASHMIRI SHAWLS.jpg"} alt=""></img>
    Kashmiri Shawls
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/WOOLEN CLOTHES/SWEATER.jpg"} alt=""></img>
    Sweater
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/WOOLEN CLOTHES/WOOLEN CAP.jpg"} alt=""></img>
    Wollen Cap
    </div>
  </div>
  <div className="card"><div className="card-in">
    <img className="card__image" style={{borderRadius:"4px"}} src={"/Productsphoto/7. CLOTHING/WOOLEN CLOTHES/WOOLEN SOCKS.jpg"} alt=""></img>
    Woolen Socks
    </div>
  </div>
        
      </div>
      
      
        
     

  
  
  
  
  
 
  

    </div>
  )
}

export default Clothing