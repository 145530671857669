import React from 'react'
import Testimonial from './Testimonial'
const Review = () => {
  return (
    <div>
        <div style={{width:"100%",height:"20vh",backgroundColor:"black",color:"white",textAlign:"center",verticalAlign:"center",paddingTop: '45px'}}>
          <h3>THIS IS WHY WE DO, WHAT WE DO!</h3>
        </div>
        <Testimonial/>
    </div>
  )
}

export default Review