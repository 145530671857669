import React from 'react'
import "./Spices.css"


const Wooden = () => {
  return (
    <div>
        <div style={{width:"100%",height:"20vh",backgroundColor:"black",color:"white",textAlign:"center",verticalAlign:"center",paddingTop: '45px'}}>
          <h1>Wooden Handicrafts</h1>
        </div>
        <div className="about-block" style={{width:"100%",minHeight:"40vh",backgroundColor:"white"}}>
          <div className='aboutBlock-left' style={{width:"100%",height:"100%",backgroundColor:"white",textAlign:"center",paddingTop:"20px"}}>
            <img src={"/Productsphoto/Handicrafts/WoodenCraft/Decorative Wooden Flower Vase.png"} style={{width:"90%",height:"90%"}}/>
          </div>
          <div className='aboutBlock-right' style={{width:"100%",height:"100%",backgroundColor:"white",paddingTop:"20px",paddingRight:"20px",paddingLeft:"20px"}}>
                <h2>About Wooden Handicrafts</h2>
                
                  <p>Wooden handicrafts are made by decorating or carving the wood to create beautiful items. Every craftsman creates a unique handicraft according to their individual talent. India is considered as the land of tradition and culture. Indian craftsmen create distinctive masterpieces with different shapes, patterns, colours and sizes. These handicrafts are famous all over the earth for their ability to last. Wooden handicrafts of India are known for its utility and beauty. These handicrafts embrace furniture, boxes, beads, finely engraved figures, accessories etc. India is a tropical country and it has deciduous or evergreen forests. Besides the wooden furniture items, beautiful toys, photo frames, dolls were also made. The art of decorating and carving the wood is considered as one of the most notable forms. It requires a lot of hard work and dedication to carve a piece of wood and make something unique and beautiful out of it.
</p>
              
          </div>

</div>
<button className="Spices-head">
        <h2>Wooden Handicrafts</h2>
      </button>
      <div style={{textAlign:"center",textDecoration:"none",color:"grey",marginTop:"10px"}}><h2>-- WOODEN EPOXY TABLE TOP --</h2></div>
      <div className="cards">
   
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/WOODEN EPOXY TABLE TOP/DINNING TABLE.jpeg"}
            alt=""
          />Dinning Table</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/WOODEN EPOXY TABLE TOP/Epoxy Resin Round Table.jpeg"
            }
            alt=""
          />Epoxy Resin Round Table</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/WOODEN EPOXY TABLE TOP/Green RESIN TABLE.jpeg"}
            alt=""
          />Green Resin Table</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/WOODEN EPOXY TABLE TOP/natural wood epoxy table.jpeg"
            }
            alt=""
          />Natural Wood Epoxy Table
          </div>
        </div>
        
      </div>
      {/* //// */}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>-- WOODEN COASTER --</h2></div>
      <div className="cards">
      
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/WOODEN COASTER/Handmade Wooden Coaster.png"}
            alt=""
          />Handmade Wooden Coaster
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/WOODEN COASTER/Nature Wood Coaster Set With Holder.png"
            }
            alt=""
          />Nature Wood Coaster Set With Holder
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/WOODEN COASTER/Round Coaster With Coaster Holder.png"}
            alt=""
          />Round Coaster With Coaster Holder</div>
        </div>
        
        
      </div>
      {/* /////// 33333 */}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>-- Toys --</h2></div>
      <div className="cards">
        
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/TOYS/LUDO DICE.jpg"}
            alt=""
          />Ludo Dice</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/TOYS/PUZZLE.jpg"
            }
            alt=""
          />Puzzle</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/TOYS/TIC TAC TOE.jpg"}
            alt=""
          />Tic Tac Toe
          </div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/TOYS/VEHICLES.jpg"
            }
            alt=""
          />Vehicles</div>
        </div>
        
      </div>
      {/* //// 4444*/}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>-- NAPKIN HOLDERS --</h2></div>
      <div className="cards">
      
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/NAPKIN HOLDERS/Adjustable Wooden Napkin Holder.png"}
            alt=""
          />Adjustable Wooden Napkin Holder</div>
        </div>
        <div className="card">
        <div className="card-in">
          
          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/NAPKIN HOLDERS/Wooden Napkin Rings.png"
            }
            alt=""
          />Wooden Napkin Rings</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/NAPKIN HOLDERS/Wooden Oval Napkin Ring.png"}
            alt=""
          />Wooden Oval Napkin Ring</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/NAPKIN HOLDERS/Wooden Square Napkin Ring.png"
            }
            alt=""
          />Wooden Square Napkin Ring</div>
        </div>
        
      </div>
      {/* //////5555 */}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>-- Kitchen Ware --</h2></div>
      <div className="cards">
       
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/KITCHEN WARE/PRINTED SPATULAS.jpg"}
            alt=""
          />Printed Spatulas</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/KITCHEN WARE/Serving Bowls.jpeg"
            }
            alt=""
          />Serving Bowls</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/KITCHEN WARE/Wooden Bowls.png"}
            alt=""
          />Wooden Bowls</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/KITCHEN WARE/WOODEN ROLLING PIN.jpeg"
            }
            alt=""
          />Wooden Rolling Pin</div>
        </div>
        
      </div>
      {/* ///////6666 */}
      <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>-- Flower Vase --</h2></div>
      <div className="cards">
        
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/FLOWER VASE/CYLINDRICAL FLOWER VASE.jpg"}
            alt=""
          />Cylindrical Flower Vase</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/FLOWER VASE/Decorative Wooden Flower Vase.png"
            }
            alt=""
          />Decorative Wooden Flower Vase</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/FLOWER VASE/Square Wooden Planter.png"}
            alt=""
          />Square Wooden Planter</div>
        </div>
        
        
      </div>
      {/* ////77777 */}
    <div style={{textAlign:"center",textDecoration:"none",color:"grey"}}><h2>-- Candle Holder & Lamp --</h2></div>
      <div className="cards">
       
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/CANDLE HOLDER & LAMP/CANDLE HOLDER.jpg"}
            alt=""
          />Candle  Holder</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/CANDLE HOLDER & LAMP/Crescent Wooden Table Lamp.jpg"
            }
            alt=""
          />Crescent Wooden Table Lamp</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={"/Productsphoto/Wooden Handicraft/CANDLE HOLDER & LAMP/LAMP.jpg"}
            alt=""
          />Lamp</div>
        </div>
        <div className="card">
        <div className="card-in">

          <img
            className="card__image"
            style={{
              
              borderRadius: "4px",
            }}
            src={
              "/Productsphoto/Wooden Handicraft/CANDLE HOLDER & LAMP/Wooden Conical Candle Holders.jpg"
            }
            alt=""
          />Wooden Conical Candle Holders</div>
        </div>
        
      </div>

  
  
  
  
  
 
  

    </div>
  )
}

export default Wooden