import React from 'react'
import "./Pannel.css"
const Pannel = () => {
  return (
    <div>
        <div className='pannel-main' scroll="no">

        </div>
    </div>
  )
}

export default Pannel